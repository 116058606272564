import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import isUndefined from 'lodash/isUndefined';
import { isFieldInvalid } from '@ebf-libs/sdk/platform/forms-validation';

@Component({
  selector: 'ebf-switchbox',
  templateUrl: './switchbox.component.html',
  styleUrls: ['./switchbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchboxComponent),
      multi: true,
    },
  ],
})
export class SwitchboxComponent implements OnInit, ControlValueAccessor {
  @Input()
  public value: boolean;
  @Input()
  public disabled: boolean;
  @Input()
  public readonly yesLabel: string;
  @Input()
  public readonly noLabel: string;
  @Input()
  public readonly colorSchema: string = 'SUCCESS';
  @Input()
  private readonly inputId: string;
  @Input()
  public readonly labelStyleClass: string;
  @Input()
  private readonly formControlName: string;
  @Input()
  public readonly inputStyleClass: string = '';

  private abstractControl: any;
  private readonly timestamp: string = String(Date.now());

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private readonly controlContainer: ControlContainer,
  ) {}

  public ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.abstractControl = this.controlContainer.control.get(this.formControlName);
    }
  }

  public get controlId(): string {
    return this.inputId ?? this.timestamp;
  }

  public onChange(value: boolean) {}
  public onTouched() {}

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public isUndefined(value: unknown): boolean {
    return isUndefined(value);
  }

  public isFieldInvalid(): boolean {
    return isFieldInvalid(this.abstractControl);
  }
}
