import { HttpResponse } from '@angular/common/http';

export const getFileNameFromContentDisposition = (
  response: HttpResponse<any>,
  fileNameKey?: string,
): string => {
  try {
    return response.headers
      .get('content-disposition')
      .split(';')
      .map((value: string) => value.trim())
      .filter((value: string) => value.toLowerCase().startsWith(fileNameKey || 'filename'))[0]
      .split('=')[1]
      .trim();
  } catch (e) {
    return '';
  }
};
