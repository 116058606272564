import { Pipe, PipeTransform } from '@angular/core';

import { EbfConfigService } from '../services/ebf-config.service';

@Pipe({
  name: 'config',
})
export class ConfigPipe implements PipeTransform {
  constructor(private readonly ebfConfigService: EbfConfigService) {}

  public transform(key: string): any {
    return this.ebfConfigService.get(key);
  }
}
