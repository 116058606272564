import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';
import { EbfLocalizationService } from '@ebf-libs/sdk/platform/localization';

@Component({
  selector: 'ebf-layouts-not-found',
  templateUrl: './layout-not-found.component.html',
  styleUrls: ['./layout-not-found.component.scss'],
})
export class LayoutNotFoundComponent extends AutoUnsubscribable implements OnInit {
  public notFoundTitle: string;
  public notFoundMessage: string;
  public homePageLabel: string;

  constructor(
    private readonly ebfLocalizationService: EbfLocalizationService,
    private readonly router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subSink.sink = this.ebfLocalizationService.localeChanged$.subscribe(() => {
      this.notFoundTitle = this.ebfLocalizationService.translate('shared.notFoundTitle');
      this.notFoundMessage = this.ebfLocalizationService.translate('shared.notFoundMessage');
      this.homePageLabel = this.ebfLocalizationService.translate('shared.homeLinkLabel');
    });
  }

  public onGoToHomeClick(): void {
    this.router.navigate(['/dashboard']);
  }
}
