import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import isEmpty from 'lodash/isEmpty';
import { isFieldInvalid } from '@ebf-libs/sdk/platform/forms-validation';
import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';

import { TimeConverter } from '../../utils/time-converter.util';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ebf-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectComponent),
      multi: true,
    },
  ],
})
export class TimeSelectComponent
  extends AutoUnsubscribable
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input()
  public value: string;
  @Input()
  public isMinutesDisabled: boolean;
  @Input()
  public name: string;
  @Input()
  public inputId: string;
  @Input()
  public isShortFormat: boolean;
  @Input()
  public clearable: boolean = true;
  @Input()
  private formControlName: string;
  @Input()
  public placeholder: string = '';
  @Input()
  public disabled: boolean;

  @ViewChild('hours')
  public readonly hoursElement: ElementRef;
  @ViewChild('minutes')
  public readonly minutesElement: ElementRef;
  @ViewChild('popover')
  public readonly popoverDirective: PopoverDirective;

  public form: UntypedFormGroup;
  public targetInputId: string;
  private abstractControl: any;

  private maxMinutesValue: number = 59;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isShortFormat && !changes.isShortFormat.isFirstChange()) {
      if (changes.isShortFormat.currentValue) {
        const [hours, _, period] = TimeConverter.convertTime24to12(this.value).split(/[\s:]+/);

        this.form.patchValue({ hours, AM: period === 'AM' });
        this.setTime();
      } else {
        const [hours] = TimeConverter.convertTime12to24(this.value).split(':');

        this.form.patchValue({ hours, AM: +hours >= 12 });
        this.setTime();
      }
    }
  }

  public ngOnInit(): void {
    this.targetInputId = this.inputId || this.name || String(Date.now());
    if (this.controlContainer && this.formControlName) {
      this.abstractControl = this.controlContainer.control.get(this.formControlName);
      this.subSink.sink = this.abstractControl.valueChanges.subscribe(value => {
        if (value) {
          const [hours, minutes] = value.split(':');

          this.hoursControl.setValue(hours);
          this.minutesControl.setValue(this.isShortFormat ? minutes.split(' ')[0] : minutes);
        }
      });
    }
    this.initForm();
  }

  public onChange(value: string) {}
  public onTouched() {}

  public get maxHoursValue(): number {
    return this.isShortFormat ? 12 : 23;
  }

  public get hoursControl(): AbstractControl {
    return this.form.get('hours');
  }

  public get minutesControl(): AbstractControl {
    return this.form.get('minutes');
  }

  public setTime(): void {
    if (!this.hoursControl.value) {
      return;
    }

    const hours =
      this.hoursControl.value.length === 1 ? `0${this.hoursControl.value}` : this.hoursControl.value;
    const minutes = !this.minutesControl.value
      ? '00'
      : this.minutesControl.value.length === 1
      ? `0${this.minutesControl.value}`
      : this.minutesControl.value;
    const interval = !this.isShortFormat ? '' : this.form.get('AM').value ? 'AM' : 'PM';

    this.writeValue(`${hours}:${minutes} ${interval}`.trim());
  }

  public setFocus(): void {
    setTimeout(() => this.hoursElement.nativeElement.focus());
  }

  public onEnter(event): void {
    event.preventDefault();
    this.popoverDirective.hide();
  }

  public onHoursFocus() {
    this.hoursElement.nativeElement.select();
  }

  public onMinutesFocus() {
    this.minutesElement.nativeElement.select();
  }

  private initForm(): void {
    const initialHours = this.value?.split(':')[0] || '';
    const initialMinutes = this.value?.split(':')[1] || '';

    this.form = this.formBuilder.group({
      hours: initialHours,
      AM: false,
      minutes: {
        value: this.isMinutesDisabled && initialMinutes ? initialMinutes : this.isMinutesDisabled ? '00' : '',
        disabled: this.isMinutesDisabled,
      },
    });
    this.subSink.sink = this.hoursControl.valueChanges.subscribe(value => this.updateHoursValue(value));
    this.subSink.sink = this.minutesControl.valueChanges.subscribe(value => this.updateMinutesValue(value));

    setTimeout(() => {
      if (this.value) {
        const [hours, minutes, period] = this.value.split(/[\s:]+/);
        this.form.setValue({ hours, minutes, AM: period === 'AM' }, { emitEvent: false });
      }
    });
  }

  private updateHoursValue(value: string): void {
    if (value === '00' && this.isShortFormat) {
      this.hoursControl.setValue(12);
      this.form.get('AM').setValue(true);
      this.minutesElement?.nativeElement?.focus();
    } else if (value.length > 2 || +value > this.maxHoursValue) {
      this.hoursControl.setValue(
        +value.slice(0, 2) > +this.maxHoursValue ? `0${value[0]}` : value.slice(0, 2),
      );
      if (!this.isMinutesDisabled) {
        this.minutesElement?.nativeElement?.focus();
        this.minutesControl.setValue(value[value.length - 1]);
      }
    } else if (value.length === 2) {
      this.minutesElement?.nativeElement?.focus();
    }
  }

  private updateMinutesValue(value: string): void {
    if (value.length > 2 || +value > this.maxMinutesValue) {
      this.minutesControl.setValue('00');
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  public onMainControlInput(keyboardEvent: Event): void {
    keyboardEvent.preventDefault();
  }

  public onMainControlFocus(event) {
    setTimeout(() => {
      if (typeof event.target.blur === 'function') {
        event.target.blur();
      }
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public isEmpty(value): boolean {
    return isEmpty(value);
  }

  public isFieldInvalid(): boolean {
    return isFieldInvalid(this.abstractControl);
  }

  public clearInput(): void {
    this.form.reset({ hours: '', minutes: '' });
    this.writeValue(null);
  }
}
