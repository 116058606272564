import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

import { EbfConfigService } from '../services/ebf-config.service';

@Pipe({
  name: 'config$',
})
export class Config$Pipe implements PipeTransform {
  constructor(private readonly ebfConfigService: EbfConfigService) {}

  public transform(key: string): any {
    return this.ebfConfigService.config$.pipe(map(() => this.ebfConfigService.get(key)));
  }
}
