<ng-template #popTemplate>
  <div class="row"
       [formGroup]="form">
    <div class="col-{{ isShortFormat ? '4' : '6' }} position-relative">
      <input #hours
             type="text"
             class="hours"
             formControlName="hours"
             ebfNumbersOnly
             data-cy-input="timeHours"
             [attr.aria-label]="'shared.hoursLabel' | translate"
             (focus)="onHoursFocus()"
             (keydown.enter)="onEnter($event)">
      <span class="separator position-absolute">:</span>
    </div>
    <div class="col-{{ isShortFormat ? '4' : '6' }}">
      <input #minutes
             type="text"
             class="minutes"
             formControlName="minutes"
             ebfNumbersOnly
             data-cy-input="timeMinutes"
             [attr.aria-label]="'shared.minutesLabel' | translate"
             (focus)="onMinutesFocus()"
             (keydown.enter)="onEnter($event)">
    </div>
    <div *ngIf="isShortFormat"
         class="col-4">
      <div>
        <input
          type="checkbox"
          id="time-format"
          name="status"
          formControlName="AM"
          data-switch="success" />
        <label for="time-format"
               data-On-label="AM"
               data-off-label="PM">
        </label>
      </div>
    </div>

  </div>
</ng-template>

<div class="position-relative">
  <input #popover="bs-popover"
         *ngIf="!disabled; else textValue"
         [id]="targetInputId"
         [outsideClick]="true"
         [value]="value"
         [popover]="popTemplate"
         type="text"
         class="form-control time time-picker {{ isFieldInvalid() ? 'is-invalid' : '' }}"
         name="daterange"
         autocomplete="off"
         containerClass="popover-time-select {{ isShortFormat ? 'popover-time-select__short' : '' }} shadow-sm"
         popoverTitle="Select time"
         placement="bottom"
         placeholder="{{ placeholder }}"
         readonly
         (focus)="onMainControlFocus($event)"
         (keydown)="onMainControlInput($event)"
         (paste)="onMainControlInput($event)"
         (onShown)="setFocus()"
         (onHidden)="setTime()"/>

  <button *ngIf="clearable && !disabled && !(value | callMethod:isEmpty)"
          [attr.aria-label]="'shared.clearLabel' | translate"
          type="button"
          class="btn position-absolute clear-button"
          (click)="clearInput()">
    <i pTooltip="{{ 'shared.clearLabel' | translate }}"
       class="dripicons-cross">
    </i>
  </button>
</div>

<ng-template #textValue>
  <div class="form-control"
       [attr.data-cy-time]="value">
    {{ value }}
  </div>
</ng-template>
