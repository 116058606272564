import { ComponentRef, Directive, ElementRef, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';
import { PreviewIconComponent } from '@ebf-libs/sdk/platform/forms/lib/view/preview-icon/preview-icon.component';

@Directive({
  selector: '[ebfPreviewInput]',
})
export class PreviewInputDirective extends AutoUnsubscribable implements OnInit {
  public inputElement: HTMLInputElement;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer2: Renderer2,
  ) {
    super();
    this.inputElement = elementRef.nativeElement;
  }

  public ngOnInit(): void {
    const componentRef: ComponentRef<PreviewIconComponent> =
      this.viewContainerRef.createComponent(PreviewIconComponent);
    const componentInstance: PreviewIconComponent = componentRef.instance;

    this.subSink.sink = componentInstance.valueChanged.subscribe((value: boolean) => {
      this.renderer2.setAttribute(this.inputElement, 'type', value ? 'text' : 'password');
    });
  }
}
