import { Pipe, PipeTransform } from '@angular/core';

export type ComponentMethod<I, O> = (value: I, ...fnArguments: any[]) => O;

@Pipe({
  name: 'callMethod',
})
export class CallMethodPipe<I, O = any> implements PipeTransform {
  public transform(value: I, methodReference: ComponentMethod<I, O>, ...fnArguments: any[]): O {
    fnArguments.unshift(value);

    return methodReference.apply(null, fnArguments);
  }
}
