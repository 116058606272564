<div>
  <input
    type="checkbox"
    [class]="inputStyleClass"
    [name]="controlId"
    [id]="controlId"
    [disabled]="disabled"
    [attr.data-switch]="colorSchema"
    [attr.aria-label]="value ? isUndefined(yesLabel) ? ('shared.yesLabel' | translate) : yesLabel : isUndefined(noLabel) ? ('shared.noLabel' | translate) : noLabel"
    [attr.aria-checked]="value"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"/>
  <label [for]="controlId"
         class="mb-0 {{ isFieldInvalid() ? 'border-danger' : '' }} {{ labelStyleClass }}"
         [attr.data-On-label]="isUndefined(yesLabel) ? ('shared.yesLabel' | translate) : yesLabel"
         [attr.data-off-label]="isUndefined(noLabel) ? ('shared.noLabel' | translate) : noLabel">
  </label>
</div>
