import { ApiError, ApiErrorItem, HttpStatus, ApiErrorCategory } from '../domain';

export class TimeoutException implements ApiError {
  public status: HttpStatus;
  public errors: ApiErrorItem[];

  constructor(message: string) {
    this.status = HttpStatus.REQUEST_TIMEOUT;
    this.errors = [{ message, category: ApiErrorCategory.REQUEST_RETRIES_EXCEEDED }];
  }
}
