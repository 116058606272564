import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, startWith, map } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { UserService } from '@ebf/core/user/services/user.service';
import { UserDto } from '@ebf/features/users/domain';
import { EventsApiService } from '@ebf/shared/events/services/events-api.service';

@Component({
  selector: 'ebf-layout-app',
  templateUrl: './layout-app.component.html',
  styleUrls: ['./layout-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutAppComponent implements OnInit {
  public menuItems: MenuItem[];
  public userMenuItems: MenuItem[];
  public selectedItem: MenuItem;
  public currentYear: number;
  public isMobileMenuOpen: boolean;
  public user: UserDto;
  public role$: Observable<string>;

  constructor(
    public readonly userService: UserService,
    private readonly eventsApiService: EventsApiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.user = this.userService.getUserSnapshot();
    this.currentYear = new Date().getFullYear();
    // TODO: websockets events listening
    // this.eventsApiService.connect();
    this.userMenuItems = [
      {
        label: 'Logout',
        command: () => {
          this.eventsApiService.disconnect();
          this.userService.logout();
        },
      },
    ];
    this.menuItems = [
      { label: 'Championships', routerLink: '/championships' },
      { label: 'Users', routerLink: '/users', visible: this.userService.isAdmin() },
      { label: 'Help', routerLink: '/faq' },
    ];
    this.selectedItem = this.menuItems[0];
    this.role$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),
      map(() => {
        const activatedChildRoute: ActivatedRouteSnapshot = this.getActivatedChildRoute(
          this.activatedRoute.snapshot,
        );
        const { id, countryId } = activatedChildRoute?.params || {};
        const targetChampionshipId: string = countryId || id;

        if (this.userService.isGuest()) {
          return null;
        }

        if (this.userService.isAdmin()) {
          return 'Admin';
        }

        if (this.userService.isResponsible(targetChampionshipId)) {
          return 'Responsible';
        }

        if (this.userService.isEventManager(targetChampionshipId)) {
          return 'Event Manager';
        }

        if (this.userService.isContentManager(targetChampionshipId)) {
          return 'Content Manager';
        }

        if (this.userService.isJudge(targetChampionshipId)) {
          return 'Judge';
        }

        return 'User';
      }),
    );
  }

  public onToggleMobile(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  private getActivatedChildRoute(activatedRouteSnapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return activatedRouteSnapshot.firstChild
      ? this.getActivatedChildRoute(activatedRouteSnapshot.firstChild)
      : activatedRouteSnapshot;
  }
}
