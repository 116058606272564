import { AbstractControl } from '@ngneat/reactive-forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const autoInsertIfSingle = <T = any>(formControl: AbstractControl, withDisable?: boolean) => {
  return (source$: Observable<any[]>) => {
    return source$.pipe(
      tap((items: any[]) => {
        if (items?.length === 1) {
          formControl.setValue(items[0]);

          if (withDisable) {
            formControl.disable();
          }
        }
      }),
    );
  };
};
