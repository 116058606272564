import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ebf-label-control',
  templateUrl: './label-control.component.html',
  styleUrls: ['./label-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelControlComponent {
  @Input()
  public readonly label: string;
  @Input()
  public readonly append: boolean;
  @Input()
  public readonly width: string = 'auto';
}
