import { map } from 'rxjs/operators';

const compareFn = (items, fieldName) =>
  items.sort((a, b) => {
    const aValue = a[fieldName] ? String(a[fieldName]).toLowerCase() : '';
    const bValue = b[fieldName] ? String(b[fieldName]).toLowerCase() : '';

    return aValue > bValue ? 1 : -1;
  });

export const sortByField = <T>(fieldName: string) => {
  return map((data: T): T => {
    if (!data) {
      return data;
    }

    if (Array.isArray(data)) {
      return compareFn(data, fieldName);
    }

    // @ts-ignore
    if (data.hasOwnProperty('items') && Array.isArray(data.items)) {
      // @ts-ignore
      return { ...data, items: compareFn(data.items, fieldName) };
    }

    return data;
  });
};
