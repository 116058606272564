import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const localVersion: string = localStorage.getItem('thg_appVersion');
          const currentVersion: string = event.headers.get('x-ui-version');

          if (currentVersion && localVersion !== currentVersion) {
            localStorage.setItem('thg_appVersion', currentVersion);
            // @ts-ignore
            location.reload(true);
          }
        }
      }),
    );
  }
}
