<div class="input-group flex-nowrap">
  <div *ngIf="label && !append" class="input-group-prepend">
    <div class="input-group-text align-self-start"
         [style.width]="width"
         [attr.data-cy-label-prepend]="label">
      {{ label }}
    </div>
  </div>
  <div class="flex-grow-1">
    <ng-content></ng-content>
  </div>
  <div *ngIf="label && append" class="input-group-append">
    <div class="input-group-text align-self-start"
         [attr.data-cy-label-append]="label">{{ label }}</div>
  </div>
</div>

