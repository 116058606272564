import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ebf-preview-icon',
  templateUrl: './preview-icon.component.html',
  styleUrls: ['./preview-icon.component.scss'],
})
export class PreviewIconComponent {
  public isShow: boolean = false;

  @Output()
  public readonly valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public toggle(): void {
    this.isShow = !this.isShow;
    this.valueChanged.emit(this.isShow);
  }
}
