import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'croppedText',
})
export class CroppedTextPipe implements PipeTransform {
  public transform(value: string, maxLength: number = 200): string {
    value = String(value);

    return value.length > maxLength ? value.slice(0, maxLength) + '...' : value;
  }
}
